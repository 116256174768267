$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    // var offset = 0; // Distance from Browserbottom & -top where the animation should start

    // function fadeInElements() {
    //     var viewPortStart = $(window).scrollTop() - 1,
    //         viewPortEnd = viewPortStart + $(window).height();


    //     $(".animateIn:visible").each(function () {
    //         var elementTop = $(this).offset().top,
    //             elementBottom = $(this).offset().top + $(this).outerHeight();

    //         if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
    //             var delay = $(this).data("animation-delay");
    //             $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
    //         } else {
    //             $(this).removeClass("animateIn--active");
    //         }
    //     });
    // }

    // $(window).scroll(function () {
    //     fadeInElements();
    // });

    // fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-navbutton").toggleClass("active");
        // $(".js-nav").slideToggle();
        $(".js-nav").toggleClass("active");
        $(".js-overlay").toggleClass("active");
    });

    $(window).resize(function resize() {
        if ($(this).width() > 700) {
            // $(".js-nav").slideUp();
            $(".js-navbutton").removeClass("active");
        }
    });



    // $(".js-nav-overlay").hide();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper
    // *
    // *
    const swiper = new Swiper('.swiper', {
        // Optional parameters
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 40,
        freeMode: true,

        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },

        scrollbar: {
            el: '.swiper-scrollbar',
        },
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                spaceBetween: 40
            },
            0: {
                spaceBetween: 20
            },


        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tabs
    // *
    // *
    $(".js-tab-button").click(function () {
        var index = $(this).index();
        $(".js-tab-wrapper").find(".js-tab-button").removeClass("active");
        $(".js-tab-wrapper").find(".js-tab-content").removeClass("active");
        $(this).addClass("active");
        $(".js-tab-wrapper").find(".js-tab-content").eq(index).addClass("active");
    });

    $(".js-tab-button").eq(0).trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter
    // *
    // *
    var $grid = $('.js-masonry').isotope({
        itemSelector: '.js-masonry-item',
        masonry: {
            columnWidth: '.imageFlow__item',
            gutter: '.imageFlow__gutter',
        }
    });

    $(window).on('load hashchange', function (e) {
        var filterURl = window.location.hash;
        if (filterURl.includes("filter")) {
            var filter = filterURl.substring(filterURl.length - 1);

            if (filter === "0") {
                $grid.isotope({ filter: '*' });
            }
            else {
                $grid.isotope({ filter: '.filter-' + filter });
            }
        }
    });
});





// * * * * * * * * * * * * * * * * * * * * * * * * *
// * animateIn - observer intersection API
// *
// *
function handleIntersection(entries) {
    entries.map((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("animateIn--active")
            // observer.unobserve(entry.target);
        }
        else {
            entry.target.classList.remove("animateIn--active")
        }
    });
}

const targets = document.querySelectorAll('.animateIn');
const options = {
    // at least 5% of the Element is in viewport
    threshold: .05,
}

const observer = new IntersectionObserver(handleIntersection, options);

// Check if observer intersection API is supported else just add active classes
if (typeof window.IntersectionObserver !== 'undefined') {
    // console.log("Observer Intersection API is supported");
    targets.forEach(target => {
        observer.observe(target);
    });

} else {
    // console.log("Observer Intersection API is NOT supported");
    targets.forEach(target => {
        target.classList.add("animateIn--active");
    });
}

$(window).on("load", function () {
    $(".js-animate-toTop").addClass("animateIn--active");
});




// * * * * * * * * * * * * * * * * * * * * * * * * *
// * topFunction
// *
// *
// When the user clicks on the button, scroll to the top of the document
// https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo#Example
function topFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}





// * * * * * * * * * * * * * * * * * * * * * * * * *
// * scroll-header
// *
// *
(function () {

    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById('js-header');

    var checkScroll = function () {

        /*
        ** Find the direction of scroll
        ** 0 - initial, 1 - up, 2 - down
        */

        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
        }
        else if (curScroll < prevScroll) {
            //scrolled down
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
    };

    var toggleHeader = function (direction, curScroll) {
        //replace 88 with the height of your header in px
        if (direction === 2 && curScroll > 88) {

            $(".js-nav").removeClass("active");
            $(".js-overlay").removeClass("active");
            $(".js-navbutton").removeClass("active");
            header.classList.add('hide');
            prevDirection = direction;
        }
        else if (direction === 1) {
            header.classList.remove('hide');
            prevDirection = direction;
        }
    };

    window.addEventListener('scroll', checkScroll);

})();



